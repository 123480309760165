var initLoader = function initLoader() {
  if (document.querySelector('.pep-product-list, .pep-accessories-list, .pep-product-details')) {
    import( /* webpackChunkName: "PEP-V2", webpackPrefetch: true */'../feature/components-pep/pep').then(function () {
      console.log('PEP-V2 Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for PEP');
      console.log(error);
    });
  }
  if (document.querySelector('.download-center, .light-gallery')) {
    import( /* webpackChunkName: "component-download-center", webpackPrefetch: true */'./../feature/component-download-center').then(function (foo) {
      console.log('download-center Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for download-center}');
      console.log(error);
    });
  }
  if (document.querySelector('.preparation-tool:not(.custom)')) {
    import( /* webpackChunkName: "preparation-tool" */'@demant/feature-counselling-tool').then(function (foo) {
      console.log('preparation-tool Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for preparation-tool}');
      console.log(error);
    });
  }
  if (document.querySelector('.flex-spot:not(.custom)')) {
    import( /* webpackChunkName: "flex-spot" */'./../feature/flex-spot/flex-spot.js').then(function (foo) {
      console.log('flex-spot Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for flex-spot');
      console.log(error);
    });
  }
  if (document.querySelector('.hearing-test-spot, .hearing-test')) {
    import( /* webpackChunkName: "hearing-test-spot" */'./../feature/component-hearing-test').then(function (foo) {
      console.log('hearing-test Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for hearing-test}');
      console.log(error);
    });
  }
  if (document.querySelector('.product-tool-conntainer, .ProductToolSpinningHead, .ProductToolColorSpot, .ProductToolDescription')) {
    import( /* webpackChunkName: "product-tool" */'./../feature/producttool/component-producttool.js').then(function (foo) {
      console.log('producttool Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for producttool}');
      console.log(error);
    });
  }
  if (document.querySelector('.component.resource-center:not(.resource-center-new)')) {
    import( /* webpackChunkName: "resource-center" */'./../feature/component-b-data-hierarchy-resource-center').then(function (foo) {
      console.log('resource-center-old Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for resource-center}');
      console.log(error);
    });
  }
  if (document.querySelector('.resource-center-new:not(.download-center)')) {
    import( /* webpackChunkName: "resource-center" */'./../feature/component-resource-center').then(function (foo) {
      console.log('resource-center Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for resource-center}');
      console.log(error);
    });
  }
  if (document.querySelector('.spare-parts-spot')) {
    import( /* webpackChunkName: "spare-parts", webpackPrefetch: false  */'./../feature/component-spare-parts-shop/index').then(function (foo) {
      console.log('spare-parts Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for spare-parts');
      console.log(error);
    });
  }
  if (document.querySelector('body:not(.pep-custom-product-list) .pep-guide:not(.pep-custom-product-list):not(.guide-new-ver)')) {
    import( /* webpackChunkName: "pep-guide-v2", webpackPrefetch: false */'@demant/product-experience-page/lib/visual-oticon/modules/PEP-guide/guide').then(function (foo) {
      console.log('pep-guide-v2 Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for pep-guide');
      console.log(error);
    });
  }
  if (document.querySelector('body:not(.pep-custom-product-list) .pep-guide.guide-new-ver:not(.pep-custom-product-list)')) {
    import( /* webpackChunkName: "pep-guide-v2", webpackPrefetch: false */'@demant/product-experience-page/lib/visual-oticon/modules/PEP-guide-v2/guide').then(function (foo) {
      console.log('pep-guide-v2 Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for pep-guide');
      console.log(error);
    });
  }
  if (document.querySelector('.pep-custom-product-list.pep-guide')) {
    import( /* webpackChunkName: "custom-list-v2", webpackPrefetch: false */'@demant/product-experience-page/lib/visual-oticon/modules/PEP-custom-product-list/index').then(function (foo) {
      console.log('custom-product-list-v2 Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for custom-product-list');
      console.log(error);
    });
  }
  if (document.querySelector('.batteryshop-product-category, .component.batteryshop, #SelectedProducts')) {
    import( /* webpackChunkName: "batteryshop", webpackPrefetch: false */'./../feature/battery-shop/battery-shop').then(function (foo) {
      console.log('batteryshop-product-category Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for batteryshop-product-category');
      console.log(error);
    });
  }
  if (document.querySelector('.hearing-center-locator')) {
    import( /* webpackChunkName: "hearing-center-locator", webpackPrefetch: false */'./../feature/component-hearing-center-locator').then(function (foo) {
      console.log('hearing-center-locator Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for .hearing-center-locator');
      console.log(error);
    });
  }
  if (document.querySelector('.date-list-search-spot--date-picker, .data-hierarchy-spot.data-list-search, .data-list-search-spot, .data-list-search-spot__adv-search-select-group, .data-list-search-spot__query, .date-list-search-spot--date-picker, .data-list-search-spot, .search-result-spot')) {
    import( /* webpackChunkName: "search-spot", webpackPrefetch: true */'../feature/list-search-components/search-components').then(function (foo) {
      console.log('search-spot Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for .search-spot');
      console.log(error);
    });
  }
  if (document.querySelector('.blog, .component.blog-author, .component.latest-blog-posts, .component.blog-list, .component.related-blogs, .component.latest-blog-posts')) {
    import( /* webpackChunkName: "blog", webpackPrefetch: true */'../feature/blog/blog').then(function (foo) {
      console.log('blog Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for  blog');
      console.log(error);
    });
  }
  if (document.querySelector('.search-field-spot, .search-result-spot, .search-icon, .search-result')) {
    import( /* webpackChunkName: "search-field", webpackPrefetch: true */'../feature/component-search.js').then(function (foo) {
      console.log('search-field Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for search-field');
      console.log(error);
    });
  }
  if (document.querySelector('.live-event-flex-spot')) {
    import( /* webpackChunkName: "live-video", webpackPrefetch: true */'../../custom-components/live-video/index').then(function (foo) {
      console.log('live-video Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for live-video');
      console.log(error);
    });
  }
  if (document.querySelector('.pim-compatibility-guide')) {
    import( /* webpackChunkName: "compatibility guide" */'@demant/feature-compatibility-guide/lib/index').then(function (foo) {
      console.log('compatibility-guide Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for .pim-compatibility-guide}');
      console.log(error);
    });
  }
  if (document.querySelector('.rich-text.popup-content')) {
    import( /* webpackChunkName: "popup" */'../../custom-components/popup/Popup').then(function (foo) {
      console.log('form-popup Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for .popup-content}');
      console.log(error);
    });
  }
};
import( /* webpackChunkName: "legacy" */
/* webpackPreload: true */
'./legacy').then(function (foo) {
  console.log('legacy Loaded');
}).catch(function (error) {
  console.log('An error occurred while loading legacy code');
  console.log(error);
});
if (document.querySelector('.component.rich-text div[pageid], .hearing-test, .component.rich-text.popup-form-id')) {
  setTimeout(function () {
    import( /* webpackChunkName: "click-dimensions-form", webpackPrefetch: true */'../feature/forms/click-dimension-form').then(function () {
      console.log('click-dimensions-form Loaded');
    }).catch(function (error) {
      console.log('An error occurred while loading the component for click-dimensions-form');
      console.log(error);
    });
  }, 500);
}
if (document.querySelector('.product-order')) {
  import( /* webpackChunkName: "product-order" */'./../feature/wdh-product-order').then(function (foo) {
    console.log('product-order Loaded');
    initLoader();
  }).catch(function (error) {
    console.log('An error occurred while loading the component for product-order');
    console.log(error);
  });
}
if (document.querySelector('.phone-compatibility:not(.custom), .phone-detection')) {
  import( /* webpackChunkName: "compatibility phones" */'../../custom-components/flex-spot/compatibility-phones/index').then(function () {
    console.log('phones compatibility Loaded');
  }).catch(function (error) {
    console.log(error);
  });
}
if (document.querySelector('.flex-spot.compatibility-guide-v3')) {
  import( /* webpackChunkName: "compatibility guide V3" */'../../custom-components/flex-spot/compatibility-guide-V3/index').then(function () {
    console.log('phones compatibility Loaded');
  }).catch(function (error) {
    console.log(error);
  });
}
if (document.querySelector("[class*='wdh-feature-b2b']") || document.querySelector('.login-spot')) {
  if (document.querySelector("[class*='wdh-feature-b2b']")) {
    import( /* webpackChunkName: "b2b-shop" */'./../feature/wdh-feature-b2b').then(function (foo) {
      console.log('b2b-shop Loaded');
      initLoader();
    }).catch(function (error) {
      console.log('An error occurred while loading the component for b2b-shop}');
      console.log(error);
    });
  }
  if (document.querySelector('.login-spot')) {
    import( /* webpackChunkName: "component-download-center", webpackPrefetch: true */'./../project/custom-a-login-spot').then(function () {
      console.log('login-spot Loaded');
      initLoader();
    }).catch(function (error) {
      console.log('An error occurred while loading the component for login-spot');
      console.log(error);
    });
  }
} else {
  initLoader();
}